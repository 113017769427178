.btn {
    @apply .font-normal;
    @apply .py-2;
    @apply .px-4;
    @apply .rounded;
    @apply .border-grey;
    @apply .border-1;
    min-width: 6rem;

    &:focus, &:active, &:hover {
        outline: none;
    }

    &.btn-xs {
        @apply .text-xs;
        @apply .py-2;
    }

    &.btn-sm {
        @apply .text-sm;
    }

    &.btn-default {
        @apply .bg-grey-lightest;
        @apply .text-grey-darkest;

        &:hover:not(:disabled) {
            @apply .bg-grey-dark;
            @apply .text-white;
        }
    }

    &.btn-primary {
        @apply .bg-accent-dark;
        @apply .text-white;

        &:hover:not(:disabled) {
            @apply .text-white;
            @apply .bg-accent-darker;
        }
    }

    &.btn-success {
        @apply bg-green-dark text-white;

        &:hover:not(:disabled) {
            @apply bg-green-darker;
        }
    }

    &.btn-danger {
        @apply .bg-red-dark;
        @apply .text-white;

        &:hover:not(:disabled) {
            @apply .bg-red-darker;
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.btn-assessment-submit {
        font-size: 1rem;
        text-transform: uppercase;
        background-color: #34495e;
        color: white !important;
        min-width: 10rem;
        margin-left: 1.5rem;
        padding: calc(0.5em - 1px) 1em;
        text-align: center;
        white-space: nowrap;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
        font-weight: bold;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 0.4em;
        height: 2.5em;
    }
}
