.status {
    @apply .p-2;
    @apply .bg-grey-darker;
    @apply .items-center;
    @apply .text-grey-lightest;
    @apply .leading-none;
    @apply .flex;
    @apply .mb-4;

    span {
        &:first-child {
            @apply .flex;
            @apply .rounded-full;
            @apply .bg-grey;
            @apply .uppercase;
            @apply .px-2;
            @apply .py-1;
            @apply .text-xs;
            @apply .font-bold;
            @apply .mr-3;
        }

        &:last-child {
            @apply .font-semibold;
            @apply .mr-2;
            @apply .text-left;
            @apply .flex-auto;
        }
    }

    &.success {
        @apply .bg-green-dark;
        @apply .text-green-lightest;

        span {
            &:first-child {
                @apply .bg-green;
            }
        }
    }

    @screen lg {
        @apply .rounded-full;
        @apply .inline-flex;
    }
}

.complete {
    @apply .text-green;
    @apply .font-bold;
}
