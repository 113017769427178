$table-border-radius: 5px;

//Override for default tabulator header styles to matdh vue good table
.tabulator.thead-medium .tabulator-header {
    color: #606266;
    vertical-align: bottom;
    padding-right: 1.5em;
    background: linear-gradient(#f4f5f8, #f1f3f6);

    .tabulator-col {
        background: linear-gradient(#f4f5f8, #f1f3f6);
    }
}

.vgt-inner-wrap {
    > div {
        &:first-child {
            border-top-right-radius: $table-border-radius;
            border-top-left-radius: $table-border-radius;
        }
        &:last-child {
            border-bottom-right-radius: $table-border-radius;
            border-bottom-left-radius: $table-border-radius;
        }
    }

    .vgt-global-search {
        background: none;
        @apply .bg-grey-lightest;
        align-items: center;
        padding: 0.5rem 0;

        .vgt-input {
            &:focus {
                @apply .border-accent;
            }
        }
    }

    .vgt-wrap__footer {
        background: none;
        @apply .bg-grey-lightest;

        .footer__navigation {
            text-transform: uppercase;

            .footer__navigation__page-btn .chevron {
                padding-bottom: 1px;

                .left,.right {
                    &::before, &::after {
                        @apply .border-accent;
                    }
                }
            }
        }
    }

    .btn {
        font-size: 0.85rem;

        &.btn-sm {
            font-size: 0.875rem;
        }

        &.btn-xs {
            font-size: 0.75rem;
        }
    }

    .button-column {
        width: 11rem;
        text-align: center;
        padding: 0.5rem;

        &.tighter {
            width: 9rem;
        }

        &.multiple {
            min-width: 16rem;
        }
    }

    .vgt-responsive {
        .vgt-table {
           thead {
               tr {
                   th {
                       font-weight: 500;
                       text-transform: uppercase;
                       font-size: 0.85rem;
                       color: black !important;
                       background: none;
                       letter-spacing: 0.03rem;
                       @apply .bg-grey-lightest;
                   }
               }
           }
           tr {
               td {
                   font-size: 0.93rem;
                   vertical-align: middle;

                   &.align-top{
                       vertical-align: top !important;
                   }

                   &:not(.button-column) {
                       padding-left: 1.5rem;
                   }
               }
           }
        }
    }
}
.location-class-overrides{
    td {
        vertical-align: top !important;
    }

    ul {
        margin: 0;
        padding: 0;

        .list-link {
            position: relative;
            display: inline-block;
            padding: 3px 12px;
            border-radius: 5px;
            margin-right: 5px;
            margin-bottom: 0;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
            text-overflow: ellipsis;

        }
    }
}
