.v--modal-overlay .v--modal-box {
    // FOR MULTISELECT DROPDOWNS.
    // This will probably need some javascript love for smaller screens that actually need to scroll the content...
    overflow: visible !important;
}

.v--modal-overlay .v--modal-background-click .v--modal {
    max-width: 70rem;
    padding: 1rem 1.75rem 1.5rem;
    border-radius: 8px;

    .dialog-content {
        padding-bottom: 2rem;

        .dialog-c-title {
            font-size: 1.75rem;
            @apply .text-primary;
        }
    }

    .vue-dialog-buttons {
        @apply flex justify-around items-center;

        border: none;

        .vue-dialog-button {
            @extend .btn;

            border-style: solid !important;
            font-size: .875rem !important;
            height: auto;
            line-height: 1.5;
            text-transform: uppercase;

            &:first-of-type {
                @apply mr-2;
                @extend .btn-default;
            }

            &:last-of-type {
                @apply ml-2;
                @extend .btn-primary;
            }
        }
    }
}

.modal-header {
    @apply .main-header;
    @apply .mb-3;
    @apply .p-0;
    @apply .text-center;
    @apply .text-primary-light;
}
