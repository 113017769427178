.metric-card {
    @apply .bg-white;
    @apply .border;
    @apply .rounded;
    @apply .shadow;
    @apply .px-6;
    @apply .py-3;

    .metric-card-content {
        @apply .flex;
        @apply .flex-row;
        @apply .items-center;

        .metric-card-icon {
            @apply .flex-shrink;
            @apply .pr-4;
        }

        .metric-card-text {
            @apply .flex-1;
            @apply .text-left;
        }
    }
}

.card {
    @apply .bg-white;
    @apply .border;
    @apply .rounded;
    @apply .shadow;
    @apply .mb-4;

    .card-title {
        @apply .border-b;
        @apply .p-3;
        @apply .tracking-wide;

        h5 {
            @apply .uppercase;
            @apply .text-grey-dark;
        }
    }

    .card-content {
        @apply .p-5;
    }
}
