.tabs {
    @apply .flex;
    @apply .border-b;

    li {
        @apply .mr-1;
        @apply .cursor-pointer;
        @apply .text-base;

        a {
            @apply .bg-white;
            @apply .inline-block;
            @apply .py-2;
            @apply .px-4;
            @apply .text-primary-lightest;
            @apply .font-normal;
            @apply .no-underline;

            &:hover {
                @apply .text-accent;
            }
        }

        &.active {
            @apply .-mb-px;

            a {
                @apply .border-l;
                @apply .border-t;
                @apply .border-r;
                @apply .rounded-t;
                @apply .text-secondary-darker;
            }
        }
    }
}

.tab-content {
    @apply .box;
    @apply .text-grey-dark;
    @apply .tracking-wide;
    @apply .font-normal;
    @apply .uppercase;
}