body {
    // font-size: 17px;
    @apply .text-grey-darker;
}

a {
    @apply .text-blue-dark;

    &:hover {
        @apply .text-blue-darker;
    }
}

blockquote {
    @apply .border-blue-light;
    @apply .border-l-4;
    @apply .font-normal;
    @apply .italic;
    @apply .my-8;
    @apply .pl-6;
    @apply .text-grey-darker;
    @apply .text-lg;
}

code {
    @apply .bg-grey-light;
    @apply .px-2;
    @apply .py-px;
    @apply .rounded;
    @apply .text-sm;
}

code.hljs {
    @apply .bg-transparent;
    @apply .p-0;

    .hljs-comment,
    .hljs-keyword,
    .hljs-meta {
        @apply .font-normal;
        //@apply .roman;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // @apply .leading-tight;
    // @apply .mb-4;
    // @apply .mt-8;
    // @apply .text-black;

    &:first-child {
        @apply .mt-0;
    }
}

h1 {
    @apply .font-extrabold;
    @apply .text-lg;
}

h2 {
    @apply .font-normal;
    @apply .text-lg;
}

h3 {
    @apply .font-bold;
    @apply .text-base;
}

h4 {
    @apply .font-normal;
    @apply .text-base;
}

h5 {
    @apply .font-normal;
    @apply .text-sm;
}

h6 {
    @apply .font-light;
    @apply .text-sm;
}

hr {
    @apply .border-b;
    @apply .border-blue-lighter;
    @apply .my-12;
    @apply .rounded-full;
}

li {
    ul,
    ol {
        @apply .my-0;
    }
}

ol,
ul {
    // @apply .my-4;
}

p {
    @apply .my-6;
}

pre {
    @apply .bg-grey-lighter;
    @apply .leading-loose;
    @apply .my-6;
    @apply .overflow-x-auto;
    @apply .p-4;
    @apply .rounded;
    @apply .shadow;
    @apply .text-base;

    code {
        @apply .bg-transparent;
        @apply .block;
        @apply .p-0;
    }
}

.box {
    @apply .p-3;
    @apply .mb-6;
    @apply .border;
    @apply .rounded;
    @apply .shadow;
    @apply .bg-white;

    &.no-shadow {
        box-shadow: initial;
    }
}

.scrollbar-visible {
    overflow: scroll;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, 0.5);
    }
}

//adding this here until tailwind is updated with latest breakpoints
@media screen and (min-width: 1536px) {
    .container{
        max-width: 1465px;
    }
}

:root {
    --nav-color: #c6cbd2;
    --nav-active-color: #ffb544;
}
