.main-header {
    @apply .p-3;
    @apply .mb-3;
    @apply .text-2xl;
    // @apply .border;
    // @apply .rounded;
    // @apply .shadow;
    @apply .uppercase;
    @apply .text-grey-dark;
    @apply .bg-white;
    @apply .tracking-wide;
    @apply .font-medium;
    padding-left: 0.1rem;
}
