.input {
    @apply .w-full;
    @apply .bg-grey-lightest;
    @apply .text-sm;
    @apply .text-grey-darkest;
    @apply .transition;
    @apply .border;
    @apply .rounded;
    @apply .py-1;
    @apply .px-2;
    @apply .pl-2;
    @apply .appearance-none;
    @apply .leading-normal;

    &:focus {
        @apply .outline-none;
        @apply .border-grey-darker;
    }
}

.select-arrow {
    @apply .pointer-events-none;
    @apply .absolute;
    @apply .inset-y-0;
    @apply .right-0;
    @apply .flex;
    @apply .items-center;
    @apply .px-2;
    @apply .text-grey-darker;
}

.portal-form {
    .form-actions-container {
        display: flex;
        justify-content: space-between;
        @apply .w-full;
        @apply .mt-8;
        @apply .px-0;

        .left-side {
            .btn {
                @apply .mr-2;
            }
        }

        .right-side {
            .btn {
                @apply .ml-2;
            }
        }
    }

    .btn {
        min-width: 5rem;
        text-transform: uppercase;
    }
}

.field-label {
    @apply .block;
    @apply .font-medium;
    @apply .leading-5;
    @apply .text-base;
    @apply .text-black;
    @apply .mb-2;
}

.form-field {
    @apply .w-full;
    @apply .mb-4;

    .form-input {
        @apply .block;
        @apply .w-full;
        @apply .text-primary;
    }

    .form-input.vue-tel-input {
        @apply flex border rounded border-gray-300;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    input[type=date] {
        padding-bottom: calc(0.5rem - 1px);
        padding-top: calc(0.5rem - 1px);
    }

    &:not(.form-field-date) input[readonly] {
        pointer-events: none;
        cursor: not-allowed;
        color: #808080;
        border: none;
    }

    &.has-error {
        .form-input, .form-textarea, .multiselect__tags {
            @apply .border-red;
        }
    }

    &.property-value {
        .label-container {
            @apply .ml-0;

            .field-label {
                @apply .text-primary;
                @apply .uppercase;
            }
        }
    }

    &.label-inline {
        @apply .flex;
        @apply .items-center;

        .label-container {
            @apply .flex-shrink-0;
            @apply .mr-3;

            .field-label {
                @apply .mb-0;
            }
        }
    }
}

.textarea-field {
    textarea {
        width: 100%;
    }
}

.radio-field, .checkbox-options-field {
    .options-container {
        > div {
            > * {
                cursor: pointer;
                user-select: none;
            }
        }
    }

    &.display-inline {
        .options-container {
            display: flex;
            margin-left: -0.25rem;

            > div {
                margin-right: 1rem;

                label {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.checkbox-field {
    input {
        margin-top: -0.5rem;
        margin-left: 0.25rem;
    }

    .label-container {
        margin-right: 0.75rem;
    }

    .help {
        margin-top: 0;
    }

    &.display-inline {
        display: flex;
        flex-wrap: wrap;

        input {
            margin-top: 0.1rem;
        }
    }
    &.check-left {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        flex-wrap: nowrap;
        align-items: center;

        &.display-inline {
            .label-container {
                margin-left: 0.75rem;
            }
            input {
                margin-top: -0.35rem;
            }
        }
    }
    &.additional-content {
        margin-top: -0.2rem;
    }
}

.multiselect {
    min-height: 42px;

    .multiselect__content-wrapper {
        .multiselect__content {
            .multiselect__element {
                .multiselect__option {
                    font-size: 0.9rem;

                    &.multiselect__option--highlight {
                        @apply .bg-accent;
                    }

                    *.multiselect__option--selected {
                        @apply .bg-secondary;
                    }
                }
            }
        }
    }

    .multiselect__tags {
        @apply .form-input;
        min-height: 42px;

        .multiselect__placeholder {
            margin-bottom: 0;
            padding-top: 0;
            color: #9fa7b3;
        }

        .multiselect__input {
            width: calc(100% - 3rem);
            min-height: initial;
            line-height: inherit;
            margin-bottom: 0;
            background-color: transparent;
            margin-left: 0;
            padding-left: 0;
            // font-size: 0.85rem;
        }

        .multiselect__single {
            margin-bottom: 0;
            padding-top: 1px;
            padding-left: 0;
            background-color: transparent;
        }

        .multiselect__tags-wrap {
            margin: -0.3rem 1.5rem -0.3rem -0.5rem;
            display: flex;
            flex-wrap: wrap;
        }

        .multiselect__tag {
            @apply .bg-primary-light;
            display: inline-flex;
            font-size: 0.85rem;
            padding: 0.25rem 0.15rem 0.25rem 0.5rem;
            margin: 0.15rem 0.25rem;
            align-items: baseline;
            justify-content: space-between;

            .multiselect__tag-icon {
                position: relative;
                margin-left: 3px;
                &:focus, &:hover {
                    @apply .bg-primary-lightest;
                    &::after {
                        @apply .text-accent-lightest;
                    }
                }
                &::after {
                    @apply .text-accent-dark;
                }
            }
        }
    }

    &.multiselect--active {
        .multiselect__tags {
            .multiselect__tags-wrap {
                margin-bottom: 0.5rem;
            }
        }
    }
}

.vdp-datepicker {
    .vdp-datepicker__calendar {
         @apply rounded-lg shadow-md mt-1;
    }

    .cell.day:hover {
        @apply border-blue rounded-lg
    }

    .cell.day.selected {
        @apply bg-blue rounded-lg text-white;
        &:hover {
            @apply bg-blue-dark;
        }
    }
}

@screen sm {
    .portal-form {
        .form-actions-container {
            @apply .px-4;

            .left-side {
                .btn {
                    @apply .mr-1;
                }
            }
            .right-side {
                .btn {
                    @apply .ml-1;
                }
            }
        }

        .btn {
            min-width: 8rem;
        }
    }
}
