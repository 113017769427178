@tailwind base;
@tailwind components;

@import '~vue-progressbar-component/src/scss/progressbar';

@import "~flatpickr/dist/flatpickr";

/*! purgecss start ignore */
@import 'base';
/*! purgecss end ignore */

// Variables
//@import 'variables';

@import 'mixins';

/*! purgecss start ignore */
@import 'transitions';
/*! purgecss end ignore */

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// Components
/* purgecss start ignore */
@import 'components/top-nav';
@import 'components/headers';
@import 'components/cards';
@import 'components/buttons';
@import 'components/forms';
@import 'components/tabs';
@import 'components/status';
@import 'components/progressbar';
@import 'components/tables';
@import 'components/modals';
/* purgecss end ignore */

@tailwind utilities;
