.top-nav {
    @apply .flex-1;
    @apply .items-center;
    @apply .px-4;
    margin-bottom: 0;

    li {
        @apply .mr-6;
        @apply .my-2;
    }

    a {
        color: var(--nav-color);
        @apply .p-1;
        @apply .block;
        // @apply .py-1;
        // @apply .pl-1;
        @apply .align-middle;
        @apply .no-underline;
        @apply .border-b-4;
        @apply .border-transparent;

        i {
            @apply .mr-3;
        }

        span {
            @apply .pb-1;
            @apply .text-sm;
        }

        &:hover,
        &.active {
            // i {
            color: var(--nav-active-color);
            // }
            border-color: var(--nav-active-color);
        }
        // &:hover,
        // &.active {
        //     @apply .text-grey-dark;
        // }
        /*
        &.dashboard {
            &:hover,
            &.active {
                @apply .text-orange;
                @apply .border-orange;
            }
        }

        &.patients {
            &:hover,
            &.active {
                // @apply .text-pink-light;
                @apply .border-pink-light;
            }
        }

        &.users {
            &:hover,
            &.active {
                // @apply .text-purple;
                @apply .border-purple;
            }
        }

        &.data-sources {
            &:hover,
            &.active {
                // @apply .text-blue;
                @apply .border-blue;
            }
        }

        &.pages {
            &:hover,
            &.active {
                // @apply .text-green;
                @apply .border-green;
            }
        }

        &.reports {
            &:hover,
            &.active {
                // @apply .text-red-dark;
                @apply .border-red-dark;
            }
        }
        */
    }

    @screen lg {
        @apply .flex;
    }

    @screen md {
        @apply .px-0;

        li {
            @apply .my-0;
        }

        a {
            @apply .py-3;

            span {
                @apply .pb-0;
            }
        }
    }
}
