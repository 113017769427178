.progressbar {
    @apply .rounded;

    height: 1.25rem;

    .progressbar-bar {
        @apply .rounded;
        height: 100%;
        transition: transform 2s ease;
    }
}
